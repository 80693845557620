import React from 'react';
// import { navigate, withPrefix } from 'gatsby';

class RedirectIndex extends React.PureComponent {
  constructor() {
    super();

    // if (typeof window !== 'undefined') {
    //   const langKey = 'en';
    //   const homeUrl = withPrefix(`/${langKey}/`);

    //   navigate(homeUrl);
    // }
  }

  render() {
    return <div />;
  }
}

export default RedirectIndex;
